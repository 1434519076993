import { Controller } from 'stimulus';
import Mixpanel from 'common/Mixpanel';

export default class extends Controller {
  connect() {
    const pageName = this.data.get('pageName') || null;
    const pageViewProps = JSON.parse(this.data.get('pageViewProps') || null);
    const loadEventName = this.data.get('loadEventName') || null;

    Mixpanel.setScreenName(pageName);
    Mixpanel.screenView(pageViewProps);

    if (loadEventName !== null) {
      Mixpanel.track(loadEventName);
    }
  }

  track(event) {
    const element = event.target;
    const eventName = element.dataset.mixpanelEventName;
    const params = JSON.parse(element.dataset.mixpanelEventParams || null);

    Mixpanel.track(eventName, params);
  }
}
