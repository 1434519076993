import { Controller } from 'stimulus';
import { toggleElement } from '../displayHelpers';

export default class extends Controller {
  static targets = ['toggleable'];

  toggle(e) {
    e.preventDefault();
    toggleElement(this.toggleableTarget);
  };
}
