import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['coachSelect', 'submitButton'];

  connect() {
    this.currentCoach = this.data.get('currentCoach');
    this.noChangeText = this.data.get('noChange');
    this.unassignAllText = this.data.get('unassignAll');
    this.reassignAllText = this.data.get('reassignAll');
  }

  updateSubmitButton(_event) {
    const selectedOption = this.coachSelectTarget.options[this.coachSelectTarget.selectedIndex];

    if (selectedOption.value) {
      if (selectedOption.label === this.currentCoach) {
        this.submitButtonTarget.disabled = true;
        this.submitButtonTarget.value = this.noChangeText;
      } else {
        this.submitButtonTarget.disabled = false;
        this.submitButtonTarget.value = this.reassignAllText;
      }
    } else {
      this.submitButtonTarget.disabled = false;
      this.submitButtonTarget.value = this.unassignAllText;
    }
  }
}
