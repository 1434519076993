import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['surveyTypeSelect', 'explanation'];

  connect() {
    this.explanations = JSON.parse(this.data.get('explanations'));

    this.surveyType = this.selectedSurveyType;
  }

  changeSurveyType(event) {
    this.explanationTarget.innerText = this.explanations[this.selectedSurveyType];

    this.surveyType = this.selectedSurveyType;
  }

  get selectedSurveyType() {
    return this.surveyTypeSelectTarget.value;
  }
}
