import { Controller } from 'stimulus';

// WARNING: This _should_ work if you have multiple copies of this in on page
//          but this totally untested, so if you want to do this please ensure
//          you actually test it.

const DOMAIN_SUGGESTIONS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com'
];

const DISTANCE_CUTOFF = 3;
const DEBOUNCE_TIMER = 1000;

export default class extends Controller {
  static targets = ['input', 'suggestion'];

  connect() {
    this.timer = null;

    this.debounce();
  }

  suggest() {
    this.debounce();
  }

  debounce() {
    if (this.timer) {
      window.clearTimeout(this.timer);
    }

    this.suggestionTarget.innerText = '';
    this.timer = window.setTimeout(this.buildSuggestion.bind(this), DEBOUNCE_TIMER);
  }

  buildSuggestion() {
    const currentValue = this.inputTarget.value;
    const currentValueSplits = currentValue.split('@');
    const currentMailbox = currentValueSplits[0];
    const currentDomain = currentValueSplits[1];

    if (currentDomain == null || currentDomain.length === 0) {
      return;
    }

    const suggestions = DOMAIN_SUGGESTIONS.map(domain => {
      return { dist: this.levenshtein(currentDomain, domain), domain: domain };
    });

    const bestSuggestion = suggestions.reduce((prev, curr) => prev.dist < curr.dist ? prev : curr);

    if (bestSuggestion.dist === 0 || bestSuggestion.dist > DISTANCE_CUTOFF) {
      this.suggestionTarget.innerHTML = '';
      return;
    }

    this.suggestionTarget.innerText = '';

    const suggestedEmail = `${currentMailbox}@${bestSuggestion.domain}`;

    const newLink = document.createElement('a');
    newLink.innerHTML = `Did you mean <b>${suggestedEmail}</b>?`;

    const reboundInputTarget = this.inputTarget;
    const reboundSuggestionTarget = this.suggestionTarget;

    newLink.addEventListener('click', () => {
      reboundInputTarget.value = suggestedEmail;
      reboundSuggestionTarget.innerText = '';
    });

    this.suggestionTarget.appendChild(newLink);
  }

  levenshtein(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    const matrix = [];

    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1,
            Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
          );
        }
      }
    }

    return matrix[b.length][a.length];
  }
}
