import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['leader', 'follower'];

  syncFollowers(e) {
    this.followerTargets.forEach((follower) => {
      follower.checked = this.leaderTarget.checked;
    });
  };

  syncLeader(e) {
    this.leaderTarget.checked = this.followerTargets.every((follower) => follower.checked);
  };
}
