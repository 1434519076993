import { Controller } from 'stimulus';
import { hideElement } from '../displayHelpers';

export default class extends Controller {
  static targets = ['addAddressButton', 'addressFields', 'clinicField', 'coachField']

  connect() {
    this.availableCoachesPerClinic = JSON.parse(this.data.get('availableCoachesPerClinic'));
  }

  showAddressFields() {
    hideElement(this.addAddressButtonTarget);
    this.addressFieldsTarget.style.display = 'flex';
  }

  updateCoachField() {
    if (this.hasCoachFieldTarget) {
      this.coachFieldTarget.options.length = 0; // Remove all options from coach select field

      this.coachFieldTarget.options[0] = new Option('', ''); // Add a blank option

      this.availableCoachesPerClinic[this.clinicFieldTarget.value].map((coach, index) => {
        this.coachFieldTarget.options[index + 1] = new Option(coach.personal_name, coach.id);
      });
    }
  }
}
