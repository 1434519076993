import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['actions'];

  prioritise() {
    const activeClass = this.data.get('activeClass');

    event.currentTarget.classList.toggle(activeClass);

    const siblings = this.actionsTargets.filter(child => {
      return child !== event.currentTarget;
    });

    for (const sibling of siblings) {
      sibling.classList.remove(activeClass);
    }
  }
}
