import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'clinics', 'medicalGroups', 'coaches', 'toggleAllMedicalGroupsButton'];

  connect() {
    this.medicalGroupsAndClinics = JSON.parse(this.data.get('medicalGroupsAndClinics'));
  }

  toggleForm() {
    this.formTarget.classList.toggle('hidden');
  }

  changeMedicalGroups(event) {
    const medicalGroup = this.medicalGroupsAndClinics.find(medicalGroup => medicalGroup.id === event.target.value);
    const clinicsToToggle = medicalGroup.clinics;

    this.clinicsTargets.forEach(function(clinic) {
      if (clinicsToToggle.includes(clinic.value)) {
        clinic.checked = event.target.checked;
      }
    });
  }

  changeClinics(event) {
    const clinic = event.target.value;

    if (clinic === 'unassigned') {
      return null;
    }

    const targetMedicalGroup = this.medicalGroupsAndClinics.find(medicalGroup => medicalGroup.clinics.includes(clinic));
    const targetGroupHasClinicsSelected = this.clinicsTargets.some(element => targetMedicalGroup.clinics.includes(element.value) && element.checked);
    const targetElement = this.medicalGroupsTargets.find(element => element.value === targetMedicalGroup.id);

    targetElement.checked = targetGroupHasClinicsSelected;
  }

  toggleAllMedicalGroups(event) {
    const checked = this.toggleAllMedicalGroupsButtonTarget.value === 'true';

    this.medicalGroupsTargets.forEach(function(medicalGroup) {
      medicalGroup.checked = !checked;
    });

    this.clinicsTargets.forEach(function(clinic) {
      clinic.checked = !checked;
    });

    this.toggleAllMedicalGroupsButtonTarget.value = !checked;
    this.toggleAllMedicalGroupsButtonTarget.innerHTML = 'Select ' + (checked ? 'all' : 'none');
  }

  toggleAllCoaches(event) {
    const checked = event.target.value === 'true';

    this.coachesTargets.forEach(function(coach) {
      coach.checked = !checked;
    });

    event.target.value = !checked;
    event.target.innerHTML = 'Select ' + (checked ? 'all' : 'none');
  }
}
