import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['recipient'];

  trigger() {
    const classToAdd = this.data.get('additionalClass');

    this.recipientTargets.forEach(function(element) {
      element.classList.add(classToAdd);
    });
  };
}
