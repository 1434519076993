import load from 'load-script';

/**
 * Download the youtube API
 * Once downloaded the API will run onYouTubeIframeAPIReady defined on the window object
 *
 * @method iframeAPIReady
 *
 * @return {Promise} Resolved with the downloaded youtube API in a ready state
 */
export function iframeAPIReady() {
  const iframeAPIReady = new Promise((resolve, reject) => {
    if (window.YT && window.YT.Player && window.YT.Player instanceof Function) {
      resolve(window.YT);

      return;
    } else {
      const protocol = window.location.protocol === 'http:' ? 'http:' : 'https:';
      load(protocol + '//www.youtube.com/iframe_api', error => {
        if (error) {
          reject(error);
        }
      });
    }

    const previous = window.onYouTubeIframeAPIReady;

    // The API will call this function when page has finished downloading
    window.onYouTubeIframeAPIReady = () => {
      if (previous) {
        previous();
      }

      resolve(window.YT);
    };
  });

  return iframeAPIReady;
}
