import { Controller } from 'stimulus';
import { showElement, hideElement } from '../displayHelpers';

export default class extends Controller {
  static targets = [
    'resourceSectionSelect',
    'resourceType',
    'imageUploadField',
    'imageFileName',
    'clearImageHiddenField',
    'documentUploadField',
    'documentFileName',
    'clearDocumentHiddenField',
    'eventField',
    'articleField'
  ];

  connect() {
    this.sectionResourceTypes = JSON.parse(this.data.get('section-config'));
    this.surveyType = this.selectedResourceSection;
    this.setResourceTypeAndToggleFields();
  }

  setResourceTypeAndToggleFields() {
    this.resourceTypeTarget.value = this.sectionResourceTypes[this.selectedResourceSection];
    this.toggleFieldsForResourceType();
  }

  toggleFieldsForResourceType() {
    if (this.resourceTypeTarget.value === 'Event') {
      this.hideElements(this.articleFieldTargets);
      this.showElements(this.eventFieldTargets);
    } else {
      this.hideElements(this.eventFieldTargets);
      this.showElements(this.articleFieldTargets);
    }
  }

  showElements(elements) {
    elements.forEach(showElement);
  }

  hideElements(elements) {
    elements.forEach(hideElement);
  }

  setImageFileName(event) {
    this.imageFileNameTarget.value = event.currentTarget.value;
  }

  clearImageFileName(event) {
    this.imageUploadFieldTarget.value = '';
    this.imageFileNameTarget.value = '';
    this.clearImageHiddenFieldTarget.value = true;
    event.preventDefault();
    event.stopPropagation();
  }

  setDocumentFileName(event) {
    this.documentFileNameTarget.value = event.currentTarget.value;
  }

  clearDocumentFileName(event) {
    this.documentUploadFieldTarget.value = '';
    this.documentFileNameTarget.value = '';
    this.clearDocumentHiddenFieldTarget.value = true;
    event.preventDefault();
    event.stopPropagation();
  }

  get selectedResourceSection() {
    return this.resourceSectionSelectTarget.value;
  }
}
