export function isHiddenElement(element) {
  return (window.getComputedStyle(element).display === 'block');
}

export function showElement(element) {
  element.style.display = 'block';
};

export function hideElement(element) {
  element.style.display = 'none';
};

export function showOrHideElement(element, show) {
  element.style.display = show ? 'block' : 'none';
};

export function toggleElement(element) {
  if (isHiddenElement(element)) {
    hideElement(element);
  } else {
    showElement(element);
  }
}
