import { Controller } from 'stimulus';
import { toggleElement } from '../displayHelpers';

export default class extends Controller {
  static targets = [
    'diabetesCheckbox', 'diabetesFields', 'chfCheckbox', 'chfFields',
    'hypertensionCheckbox', 'hypertensionFields', 'copdCheckbox', 'copdFields',
    'trackingBloodPressure'
  ]

  connect() {
  }

  toggleDiabetesFields() {
    toggleElement(this.diabetesFieldsTarget);
  }

  toggleChfFields() {
    toggleElement(this.chfFieldsTarget);
  }

  toggleHypertensionFields() {
    toggleElement(this.hypertensionFieldsTarget);
  }

  toggleCopdFields() {
    toggleElement(this.copdFieldsTarget);
  }

  toggleChronicPainFields() {
  }

  toggleObesityFields() {}

  togglePrediabetesFields() {}

  toggleAnxietyFields() {}

  toggleDepressionFields() {}

  enableTrackingBloodPressure() {
    this.checkBloodPressureCheckboxesWithValue('true');
  }

  disableTrackingBloodPressure() {
    this.checkBloodPressureCheckboxesWithValue('false');
  }

  checkBloodPressureCheckboxesWithValue(valueToCheck) {
    this.trackingBloodPressureTargets.map(checkbox => {
      checkbox.checked = checkbox.value === valueToCheck;
    });
  }
}
