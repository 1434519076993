import mixpanel from 'mixpanel-browser';

let _screenName = null;
const _eventBuffer = [];

const Mixpanel = {
  setScreenName: (screenName) => {
    _screenName = screenName;
  },
  init: (projectToken, debugMode) => {
    mixpanel.init(projectToken, { api_transport: 'sendBeacon', debug: debugMode });
    processEventBuffer();
  },
  identify: (analyticsUUID) => {
    mixpanel.identify(analyticsUUID);
  },
  track: (eventName, params = {}) => {
    sendEvent(eventName, params);
  },
  screenView: (params = {}) => {
    sendEvent('Screen View', params);
  }
};

function processEventBuffer() {
  if (!mixpanel.__loaded) {
    return;
  }

  while (_eventBuffer.length > 0) {
    const event = _eventBuffer.pop();
    sendEvent(event.eventName, event.properties);
  }
}

function sendEvent(eventName, properties = {}) {
  if (!mixpanel.__loaded) {
    _eventBuffer.push({ eventName: eventName, properties: properties });
    return;
  }

  if (!eventName) {
    return;
  }

  var defaultParams = _screenName ? { 'Screen Name': _screenName } : {};
  var params = Object.assign(defaultParams, properties);
  mixpanel.track(capitaliseEventName(eventName), params);
}

function capitaliseEventName(eventName) {
  var words = [];
  eventName.split(' ').forEach(function(word) {
    words.push(word.charAt(0).toUpperCase() + word.slice(1));
  });

  return words.join(' ');
}

export default Mixpanel;
