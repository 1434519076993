import { Controller } from 'stimulus';
import { getCSRFToken } from '../apiHelpers';

export default class extends Controller {
  static targets = ['nag', 'answer'];

  answer() {
    this.hideNagBanner();

    this.snoozeNag(this.answerTarget.dataset.slug);

    return true;
  }

  dismiss(e) {
    this.hideNagBanner();

    this.snoozeNag();

    e.preventDefault();
  };

  hideNagBanner() {
    this.nagTarget.style.display = 'none';
  }

  snoozeNag(slug) {
    var url = '/api/v1/survey_nag';

    if (slug != null) {
      url += `?survey_slug=${slug}`;
    }

    return fetch(url, {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': getCSRFToken()
      }
    });
  }
}
