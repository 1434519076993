import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['changeIndicator', 'submitButton'];

  connect() {
    this.currentCoach = this.data.get('currentCoach');
    this.noChangeText = this.data.get('noChange').toUpperCase();
    this.unassignText = this.data.get('unassign').toUpperCase();
    this.reassignText = this.data.get('reassign').toUpperCase();
  }

  updateChangeIndicatorAndSubmitButton(event) {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const changeIndicator = this.changeIndicatorTargets.find(indicator => indicator.dataset.patientId === event.target.dataset.patientId);

    if (selectedOption.value) {
      if (selectedOption.label === this.currentCoach) {
        changeIndicator.children[0].innerHTML = this.noChangeText;
        changeIndicator.classList.remove('is-warning', 'is-danger');
        changeIndicator.classList.add('is-info');
      } else {
        changeIndicator.children[0].innerHTML = this.reassignText;
        changeIndicator.classList.remove('is-info', 'is-danger');
        changeIndicator.classList.add('is-warning');
      }
    } else {
      changeIndicator.children[0].innerHTML = this.unassignText;
      changeIndicator.classList.remove('is-info', 'is-warning');
      changeIndicator.classList.add('is-danger');
    }

    if (this.changeIndicatorTargets.every(indicator => indicator.children[0].innerHTML === this.noChangeText)) {
      this.submitButtonTarget.disabled = true;
    } else {
      this.submitButtonTarget.disabled = false;
    }
  }
}
