import { Controller } from 'stimulus';
import Toast from 'common/toast';

export default class extends Controller {
  connect() {
    const message = this.data.get('message');
    const expires = this.data.get('expires');

    if (message) {
      if (expires) {
        Toast.add(message, { expires: expires });
      } else {
        Toast.add(message);
      }
    }

    this.element.parentElement.removeChild(this.element);
  }
}
