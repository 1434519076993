import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['surveySlugSelect', 'recentActivitySummary'];

  connect() {
    this.recentActivitySummary = JSON.parse(this.data.get('recentActivitySummary'));

    this.surveySlug = this.selectedSurveySlug;
  }

  changeSurveySlug(event) {
    this.recentActivitySummaryTarget.innerText = this.recentActivitySummary[this.selectedSurveySlug];

    this.surveySlug = this.selectedSurveySlug;
  }

  get selectedSurveySlug() {
    return this.surveySlugSelectTarget.value;
  }
}
