import { Controller } from 'stimulus';
import fetch from 'isomorphic-fetch';
import { getCSRFToken } from '../apiHelpers';
import { showElement, hideElement } from '../displayHelpers';

export default class extends Controller {
  static targets = ['up', 'down', 'submitButton', 'progressDiv', 'startButton', 'cancelButton', 'resource', 'resourceActions', 'resourceReorderButtons'];

  connect() {
    if (this.resourceTargets.length > 1) {
      showElement(this.startButtonTarget);
    }
  }

  showOrderingForm() {
    hideElement(this.startButtonTarget);
    showElement(this.submitButtonTarget);
    this.hideElements(this.resourceActionsTargets);
    this.showElements(this.resourceReorderButtonsTargets);
    this.enable([this.upTargets, this.downTargets]);
  }

  hideOrderingForm() {
    showElement(this.startButtonTarget);
    hideElement(this.submitButtonTarget);
    this.showElements(this.resourceActionsTargets);
    this.hideElements(this.resourceReorderButtonsTargets);
  }

  submit() {
    hideElement(this.submitButtonTarget);
    this.showProgress('Submitting...');

    this.disable([this.upTargets, this.downTargets]);

    this.submitNewOrder().then(response => {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error('Response was not OK.');
      }
    }).then(json => {
      if (JSON.parse(json).success) {
        this.hideOrderingForm();
        this.showProgress('Updated!');
      } else {
        throw new Error('Response was not OK.');
      }
    }).catch(_ => {
      this.showProgress('There has been a problem submitting your request. You may want to reload the page and try again.');
    });
  }

  up(event) {
    const id = event.currentTarget.dataset.resourceId;
    const node = this.resourceTargets.find((row) => { return row.dataset.resourceId === id; });
    const nodeAbove = this.resourceTargets[this.resourceTargets.indexOf(node) - 1];

    if (nodeAbove) {
      nodeAbove.insertAdjacentElement('beforebegin', node);
    }
  }

  down(event) {
    const id = event.currentTarget.dataset.resourceId;
    const node = this.resourceTargets.find((row) => { return row.dataset.resourceId === id; });
    const nodeBelow = this.resourceTargets[this.resourceTargets.indexOf(node) + 1];

    if (nodeBelow) {
      nodeBelow.insertAdjacentElement('afterend', node);
    }
  }

  showElements(elementOrElements) {
    this.mapOverElements(elementOrElements, (el) => { showElement(el); });
  }

  hideElements(elementOrElements) {
    this.mapOverElements(elementOrElements, (el) => { hideElement(el); });
  }

  disable(elementOrElements) {
    this.mapOverElements(elementOrElements, (el) => { el.disabled = true; });
  }

  enable(elementOrElements) {
    this.mapOverElements(elementOrElements, (el) => { el.disabled = false; });
  }

  mapOverElements(elementOrElements, f) {
    if (elementOrElements) {
      const elements = [].concat(elementOrElements);
      elements.forEach(f);
    }
  }

  submitNewOrder() {
    const values = this.resourceTargets.map(node => node.dataset.resourceId);
    const body = { resource_ids: values };

    return fetch(this.data.get('url'), {
      method: 'PATCH',
      credentials: 'same-origin',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken()
      }
    });
  }

  showProgress(message) {
    this.progressDivTarget.innerHTML = message;
    showElement(this.progressDivTarget);
  }
}
