import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['height', 'feet', 'inches', 'initialWeight', 'initialBmi', 'week16Weight', 'week16Bmi', 'week28Weight', 'week28Bmi'];

  connect() {
    this.useImperial = JSON.parse(this.data.get('useImperial'));

    this.updateAllBmi();

    if (this.useImperial) {
      this.setFeetAndInches();
    }
  }

  changeHeight(event) {
    this.updateAllBmi();
  }

  updateAllBmi() {
    this.height = this.heightTarget.value;

    this.updateInitialBmi();
    if (this.hasWeek16BmiTarget) { this.updateWeek16Bmi(); }
    if (this.hasWeek28BmiTarget) { this.updateWeek28Bmi(); }
  }

  updateInitialBmi(_event) {
    this.initialBmiTarget.value = this.calculateBmi(this.initialWeightTarget.value);
  }

  updateWeek16Bmi(_event) {
    this.week16BmiTarget.value = this.calculateBmi(this.week16WeightTarget.value);
  }

  updateWeek28Bmi(_event) {
    this.week28BmiTarget.value = this.calculateBmi(this.week28WeightTarget.value);
  }

  calculateBmi(weight) {
    if (!this.height || !weight || (isNaN(this.height) || isNaN(weight))) {
      return '-';
    }

    if (this.useImperial) {
      return Number((703 * weight) / (this.height * this.height)).toFixed(2);
    } else {
      const heightInMetres = this.height / 100;
      return Number(weight / (heightInMetres * heightInMetres)).toFixed(2);
    }
  }

  combineFeetAndInches() {
    this.normalizeFeetAndInches();

    const feet = +this.feetTarget.value;
    const inches = +this.inchesTarget.value;

    this.heightTarget.value = feet * 12 + inches;
    this.heightTarget.dispatchEvent(new Event('change'));
  }

  setFeetAndInches() {
    if (this.heightTarget.value) {
      this.feetTarget.value = Math.floor(this.heightTarget.value / 12);
      this.inchesTarget.value = (this.heightTarget.value % 12).toFixed(1);
    }
  }

  normalizeFeetAndInches() {
    const feet = +this.feetTarget.value;
    const inches = +this.inchesTarget.value;

    if (feet < 0) {
      this.feetTarget.value = 0;
    }

    if (inches < 0) {
      this.inchesTarget.value = 0;
    } else if (inches >= 12) {
      this.feetTarget.value = Math.floor(feet + (inches / 12));
      this.inchesTarget.value = (inches % 12);
    }

    if (!(Math.floor(inches) === inches)) {
      this.inchesTarget.value = (+this.inchesTarget.value).toFixed(1);
    }

    if (!(Math.floor(feet) === feet)) {
      this.feetTarget.value = (+this.feetTarget.value).toFixed(0);
    }
  }
}
