import { Controller } from 'stimulus';
import Mixpanel from 'common/Mixpanel';

export default class extends Controller {
  connect() {
    const railsEnv = this.data.get('railsEnv');
    const analyticsUUID = this.data.get('analytics-uuid') || undefined;
    const mixpanelToken = this.data.get('mixpanel-token');
    if (mixpanelToken) {
      Mixpanel.init(mixpanelToken, !(railsEnv === 'production' || railsEnv === 'staging'));

      if (analyticsUUID) {
        Mixpanel.identify(analyticsUUID);
      }
    }
  }
}
