import { Controller } from 'stimulus';
import { iframeAPIReady } from 'common/youtube-utils';
import mixpanel from 'mixpanel-browser';

export default class extends Controller {
  static targets = ['skipButton', 'continueButton'];

  connect() {
    const loadYoutubeApi = iframeAPIReady();
    var videoStarted = false;

    loadYoutubeApi.then(YT => {
      window.player = new YT.Player('introduction-video-iframe', {
        events: {
          onStateChange: (event) => {
            switch (event.data) { // https://developers.google.com/youtube/iframe_api_reference#Playback_status
              case 0:
                this.continueButtonTarget.classList.remove('hidden');
                this.skipButtonTarget.classList.add('hidden');
                mixpanel.track('Introduction Video Completed');

                break;
              case 1:
                if (videoStarted === false) {
                  mixpanel.track('Introduction Video Started');
                  videoStarted = true;
                }

                break;
            }
          }
        }
      });
    }).catch(error => {
      Rollbar.error('Error loading youtube API: ' + error);
    });
  }
}
