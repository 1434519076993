import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['usernameSubmit', 'accessUsername', 'accessUsernameControl'];

  onUsernameChange() {
    var usernameEntered = this.accessUsernameTarget.value.length > 0;
    this.accessUsernameTarget.classList.remove('has-error');

    this.usernameSubmitTarget.disabled = !usernameEntered;
  }
}
