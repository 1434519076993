
export default class Toast {
  static setupForElm(app) {
    app.ports.addMessage.subscribe(function(data) {
      Toast.add(data.message, data);
    });
  }

  // This adds a toast notification to the dom.
  // It expects a #toasts-container div to exist, and sets attributes to allow the
  // our standard show-hide stimulus controller to handle dismissing.
  // This function is also wrapped by the toast stimulus controller which
  // allows server side rendered code to trigger toasts by adding nodes to the dom.
  static add(msg, options = {}) {
    // TODO: Use HTML5 <template> after IE11 deprec
    const toastContainer = document.createElement('div');
    toastContainer.classList.add('is-hidden');
    toastContainer.classList.add('is-auto-dismissed');
    toastContainer.setAttribute('data-controller', 'show-hide');
    toastContainer.setAttribute('data-target', 'show-hide.toggleable');

    const toast = document.createElement('div');
    toast.classList.add('mn-flash');

    // Allows a toast to automatically dimiss itself
    // after a given duration. Should be an integer representing
    // milliseconds.
    if (options.expires) {
      const progress = document.createElement('span');
      progress.classList.add('mn-flash-progress');

      // This is the only place we actually configure how
      // long until the toast automatically dismisses. Everything
      // else is keyed off the events of animations completing.
      progress.style.transitionDuration = options.expires + 'ms';
      toast.appendChild(progress);

      // 'transitionend' is called when all property transitions
      // have completely.
      progress.addEventListener('transitionend', () => {
        toastContainer.classList.add('is-hidden');

        toastContainer.addEventListener('transitionend', e => {
          // Disregard any other transitions - we need to know
          // if the thing is now invisible, so it can be safely
          // removed without causing a jolt in the page.
          if (e.propertyName !== 'opacity') return;

          toastContainer.remove();
        });
      });

      // Wait for the element to be added to the DOM
      // before starting the animation, otherwise the
      // browser will 'optimise' away our nice animation
      window.setTimeout(function() {
        progress.style.width = '100%';
      }, 100);
    }

    if (options.acceptURL && options.acceptText && options.denyText) {
      // If the accept/deny options are present, we construct a flash
      // with an accept URL and a deny link to dismiss the flash.
      const acceptLink = document.createElement('a');
      acceptLink.classList.add('mn-flash-link');
      acceptLink.setAttribute('href', options.acceptURL);
      acceptLink.textContent = options.acceptText;

      const denyLink = document.createElement('a');
      denyLink.classList.add('mn-flash-link');
      denyLink.setAttribute('href', '#');
      denyLink.setAttribute('data-action', 'show-hide#toggle');
      denyLink.textContent = options.denyText;

      toast.appendChild(denyLink);
      toast.appendChild(acceptLink);
    } else {
      // Otherwise default to a simple message flash with a dismiss
      // icon.
      const dismiss = document.createElement('a');
      dismiss.classList.add('mn-flash-link');
      dismiss.setAttribute('href', '#');
      dismiss.setAttribute('data-action', 'show-hide#toggle');

      const icon = document.createElement('i');
      icon.classList.add('fas');
      icon.classList.add('fa-times');

      dismiss.appendChild(icon);
      toast.appendChild(dismiss);
    }

    const msgNode = document.createTextNode(msg);
    toast.appendChild(msgNode);

    toastContainer.appendChild(toast);

    const target = document.querySelector('#toasts-container');

    // IE11 workaround :( - use `.prepend` when we can drop IE11
    if (target.firstChuld) {
      target.insertBefore(toastContainer, target.firstChild);
    } else {
      target.appendChild(toastContainer);
    }

    window.setTimeout(function() {
      toastContainer.classList.remove('is-hidden');
    }, 50);
  }
}
