import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['enrolEmail', 'enrolPassword', 'enrolDob', 'submit'];

  onDataChanged() {
    var emailEntered = this.enrolEmailTarget.value.length > 0;
    var passwordEntered = this.enrolPasswordTarget.value.length > 0;
    var dobDayEntered = this.enrolDobTargets[0].selectedIndex !== 0;
    var dobMonthEntered = this.enrolDobTargets[1].selectedIndex !== 0;
    var dobYearEntered = this.enrolDobTargets[2].selectedIndex !== 0;
    var allDataEntered = emailEntered && passwordEntered && dobDayEntered && dobMonthEntered && dobYearEntered;

    this.submitTarget.disabled = !allDataEntered;
  }
}
