import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['mnSelect'];

  disable(event) {
    event.preventDefault();

    this.mnSelectTargets.forEach(function(select) {
      if (select.value !== 'none') {
        select.value = 'none';

        const event = new Event('change');
        select.dispatchEvent(event);
      }
    });
  }
}
